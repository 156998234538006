<template>
  <div class="privince-all">
    <div
      class="privince-all__item"
      v-for="(item, index) of areasPrivinceInfo"
      :key="index"
    >
      <div class="subtitle">
        <el-row>
          <el-col
            :xs="6"
            :sm="8"
            :md="9"
            :lg="9"
            class="span col-lg-4 col-md-4 col-sm-3"
          ></el-col>
          <el-col
            :xs="12"
            :sm="8"
            :md="6"
            :lg="6"
            class="span col-lg-3 col-md-4 col-sm-6"
          >
            <span>{{ item[0].area }}</span>
          </el-col>
          <el-col
            :xs="6"
            :sm="8"
            :md="9"
            :lg="9"
            class="span col-lg-4 col-md-4 col-sm-3"
          ></el-col>
        </el-row>
      </div>
      <el-row class="privince">
        <template v-for="subItem of item">
          <el-col
            :xs="12"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
            class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
            :key="subItem.id"
          >
            <div class="privince-item" @click="getPrivinceInfo(subItem)">
              <img
                src="@/assets/images/map/map-gd.png"
                alt="map"
                v-if="subItem.provinceName === '广东'"
              />
              <img
                src="@/assets/images/map/map-js.png"
                alt="map"
                v-else-if="subItem.provinceName === '江苏'"
              />
              <img
                src="@/assets/images/map/map-sc.png"
                alt="map"
                v-else-if="subItem.provinceName === '四川'"
              />
              <img
                src="@/assets/images/map/map-zj.png"
                alt="map"
                v-else-if="subItem.provinceName === '浙江'"
              />
              <img
                src="@/assets/images/map/map-sd.png"
                alt="map"
                v-else-if="subItem.provinceName === '山东'"
              />
              <img
                src="@/assets/images/map/map-sh.png"
                alt="map"
                v-else-if="subItem.provinceName === '上海'"
              />
              <img
                src="@/assets/images/map/map-anh.png"
                alt="map"
                v-else-if="subItem.provinceName === '安徽'"
              />
              <img
                src="@/assets/images/map/map-hb.png"
                alt="map"
                v-else-if="subItem.provinceName === '湖北'"
              />
              <img
                src="@/assets/images/map/map-chq.png"
                alt="map"
                v-else-if="subItem.provinceName === '重庆'"
              />
              <img
                src="@/assets/images/map/map-hn.png"
                alt="map"
                v-else-if="subItem.provinceName === '河南'"
              />
              <img
                src="@/assets/images/map/fujian.png"
                alt="map"
                v-else-if="subItem.provinceName === '福建'"
              />
              <img
                src="@/assets/images/map/jiangxi.png"
                alt="map"
                v-else-if="subItem.provinceName === '江西'"
              />
              <img
                src="@/assets/images/map/beijin.png"
                alt="map"
                v-else-if="subItem.provinceName === '北京'"
              />
              <img
                src="@/assets/images/map/tianjin.png"
                alt="map"
                v-else-if="subItem.provinceName === '天津'"
              />
              <img
                src="@/assets/images/map/shanxi.png"
                alt="map"
                v-else-if="subItem.provinceName === '山西'"
              />
              <img
                src="@/assets/images/map/hebei.png"
                alt="map"
                v-else-if="subItem.provinceName === '河北'"
              />
              <img
                src="@/assets/images/map/neimenggu.png"
                alt="map"
                v-else-if="subItem.provinceName === '内蒙古'"
              />
              <img
                src="@/assets/images/map/hunan.png"
                alt="map"
                v-else-if="subItem.provinceName === '湖南'"
              />
              <img
                src="@/assets/images/map/guangxi.png"
                alt="map"
                v-else-if="subItem.provinceName === '广西'"
              />
              <img
                src="@/assets/images/map/hainan.png"
                alt="map"
                v-else-if="subItem.provinceName === '海南'"
              />
              <img
                src="@/assets/images/map/guizhou.png"
                alt="map"
                v-else-if="subItem.provinceName === '贵州'"
              />
              <img
                src="@/assets/images/map/yunnan.png"
                alt="map"
                v-else-if="subItem.provinceName === '云南'"
              />
              <img
                src="@/assets/images/map/xizang.png"
                alt="map"
                v-else-if="subItem.provinceName === '西藏'"
              />
              <img
                src="@/assets/images/map/shanxishen.png"
                alt="map"
                v-else-if="subItem.provinceName === '陕西'"
              />
              <img
                src="@/assets/images/map/gansu.png"
                alt="map"
                v-else-if="subItem.provinceName === '甘肃'"
              />
              <img
                src="@/assets/images/map/qinghai.png"
                alt="map"
                v-else-if="subItem.provinceName === '青海'"
              />
              <img
                src="@/assets/images/map/ningxia.png"
                alt="map"
                v-else-if="subItem.provinceName === '宁夏'"
              />
              <img
                src="@/assets/images/map/xinjiang.png"
                alt="map"
                v-else-if="subItem.provinceName === '新疆'"
              />
              <img
                src="@/assets/images/map/heilongjiang.png"
                alt="map"
                v-else-if="subItem.provinceName === '黑龙江'"
              />
              <img
                src="@/assets/images/map/jilings.png"
                alt="map"
                v-else-if="subItem.provinceName === '吉林'"
              />
              <img
                src="@/assets/images/map/liaoningshen.png"
                alt="map"
                v-else-if="subItem.provinceName === '辽宁'"
              />
              <span>
                {{
                  subItem.provinceName === '上海' ||
                  subItem.provinceName === '天津' ||
                  subItem.provinceName === '北京' ||
                  subItem.provinceName === '重庆'
                    ? subItem.provinceName + '市'
                    : subItem.provinceName === '内蒙古' ||
                      subItem.provinceName === '黑龙江'
                    ? subItem.provinceName
                    : subItem.provinceName + '省'
                }}
              </span>
            </div>
          </el-col>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
  name: 'PrivinceAll',
  data() {
    return {
      privinces: [
        '广东省',
        '江苏省',
        '四川省',
        '浙江省',
        '山东省',
        '上海市',
        '安徽省',
        '湖北省',
        '重庆市',
        '河南省',
      ],
      privinceAll: '',
      privinceAllInfo: [
        {
          hd: [
            '上海市',
            '江苏省',
            '浙江省',
            '安徽省',
            '福建省',
            '山东省',
            '江西省',
          ],
        },
        {
          hb: ['北京市', '天津市', '山西省', '河北省', '内蒙古'],
        },
        {
          hz: [],
        },
      ],
      areasPrivinceInfo: '',
    }
  },
  created() {
    // this.getAllProvince();
    this.getPrivinceInfo()
  },
  methods: {
    getAllProvince() {
      request
        .getAllProvince()
        .then((res) => {
          console.log(res)
          if (res.data.status === 'ok') {
            this.privinceAll = res.data.result
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getPrivinceInfo(privince) {
      // let code;
      // let params = null;
      console.log('privince')
      console.log(privince)
      console.log('end-privince')
      if (privince) {
        privince.provinceName =
          privince.provinceName === '上海' ||
          privince.provinceName === '天津' ||
          privince.provinceName === '北京' ||
          privince.provinceName === '重庆'
            ? privince.provinceName + '市'
            : privince.provinceName === '内蒙古' ||
              privince.provinceName === '黑龙江'
            ? privince.provinceName
            : privince.provinceName + '省'
        this.$store.commit('setProvinceInfo', privince)
        localStorage.setItem('provinceInfo', JSON.stringify(privince))
        this.$router.push({
          path: '/searchprovinceinfo',
        })
        // location.href = privince.examinfoLink
        // params = {
        //   provinceCode: code
        // }
        // this.privinceAll.some(item => {
        //   if (item.name.indexOf(privince) != -1) {
        //     code = item.code
        //     request.getWebsiteLink(params).then(res => {
        //       console.log(res);
        //     }).catch(err => {
        //       console.log(err)
        //     })
        //     return true
        //   }
        // })
      } else {
        // let loadingInstance = this.$loading.service({
        //   fullscreen: true,
        // })

        request
          .getWebsiteLink({ provinceName: '' })
          .then((res) => {
            // console.log(res);
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              // loadingInstance.close()
            })
            if (res.data.status === 'ok') {
              // this.areaPrivinceInfo
              let areasPrivinceInfoAll = res.data.result
              let areasArr = []
              let areasPrivinceInfo = []
              let areaPrivinceInfo = []
              let areas
              let areasSet
              // areasPrivinceInfoAll.map(item => {
              // })
              areasPrivinceInfoAll.sort((a, b) => {
                return a.id - b.id
              })
              areasPrivinceInfoAll.map((item) => {
                areasArr.push(item.area)
              })
              areasSet = new Set(areasArr)
              areas = Array.from(areasSet)
              // console.log(areasSet)
              // console.log(areas)
              areas.map((item) => {
                areaPrivinceInfo = []
                areasPrivinceInfoAll.map((subItem) => {
                  if (item === subItem.area) {
                    areaPrivinceInfo.push(subItem)
                  }
                })
                areasPrivinceInfo.push(areaPrivinceInfo)
              })
              this.areasPrivinceInfo = areasPrivinceInfo
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/styles.scss';

.privince-all {
  margin: 0 auto;
  max-width: 1140px;
  padding-bottom: 8%;
  text-align: center;

  .subtitle {
    margin-top: 60px;
    padding: 0 4%;

    .el-row {
      vertical-align: middle;
    }

    span {
      font-size: 36px;
      color: #333333;
    }

    .span:first-child,
    .span:last-child {
      display: inline-block;
      // width: 400px;
      height: 2em;
      @include line-center;
    }

    .span:nth-child(2) {
      display: inline-block;
      padding: 0 40px;
      font-size: 16px;
      color: #999;
    }
  }

  .privince {
    padding-top: 50px;
    // display: flex;
    // justify-content: space-between;

    .privince-item {
      // float: left;
      // margin-right: 17.5px;
      // margin-left: 17.5px;
      // padding: 0 17.5px;
      margin: 0 auto;
      margin-bottom: 30px;
      width: 258px;
      width: 200px;
      // width: 100%;
      // height: 258px;
      height: 200px;
      // width: 100%;
      // padding: 50% 0;
      // line-height: 260px;
      line-height: 200px;
      border-radius: 50%;
      border: 1px solid #e9e9e9;
      font-size: 36px;
      color: #555;
      position: relative;
      transition: all 0.5s;
      cursor: pointer;

      img {
        position: absolute;
        top: 0;
        top: 5%;
        left: 0;
        left: 5%;
        height: 100%;
        height: 90%;
        width: 100%;
        width: 90%;
        border-radius: 50%;
        // z-index: -1;
        z-index: 0;
        object-fit: contain;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        // box-shadow: 0 0 15px $primary;
        box-shadow: 0 0 15px rgba(88, 88, 88, 0.2);
        transform: scale(1.05);
      }
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

@media (max-width: 767.98px) {
  .privince-all {
    .subtitle {
      span {
        font-size: 20px;
      }

      .span:first-child,
      .span:last-child {
        height: 1.2em;
      }

      .span:nth-child(2) {
        padding: 0 20px;
      }
    }

    .privince {
      .privince-item {
        width: 160px;
        height: 160px;
        line-height: 160px;
        font-size: 30px;
      }
    }
  }
}
</style>

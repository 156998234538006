<!--
 * @Author: js
 * @Date: 2021-10-21 14:39:34
 * @LastEditors: js
 * @LastEditTime: 2022-07-20 10:56:44
 * @Description: desc
 * @FilePath: /zktree_official_website/src/components/banner/BannerPrivinceInfo.vue
-->
<template>
  <div class="banner-container">
    <img src="@/assets/images/banner/banner-privince-bg-v1.png" alt="banner">
    <div class="container-of">

    </div>
  </div>
</template>

<script>
  export default {
    name: 'BannerPrivinceInfo'
  }
</script>

<style>
</style>

<!--
 * @Author: js
 * @Date: 2021-10-21 14:39:34
 * @LastEditors: js
 * @LastEditTime: 2022-07-20 11:41:10
 * @Description: desc
 * @FilePath: /zktree_official_website/src/views/PrivinceInfo.vue
-->
<template>
  <div class="privince-info">
    <BannerPrivinceInfo></BannerPrivinceInfo>
    <EpidemicInfo />
    <PrivinceAll></PrivinceAll>
    <!-- <div class="province-news">
      <div class="title">各省份延期动态数据统计</div>
      <div class="title-sub">截止{{ date }}</div>
      <div class="province-news__block number">
        <div class="left">
          <div class="number-title red">
            {{ delayNum }}省
          </div>
          <div class="number-subtitle">延期</div>
        </div>
        <div class="right">
          <div class="number-title green">
            {{ 31 - delayNum }}省
          </div>
          <div class="number-subtitle">计划开考</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="province-news__block">
        <div class="news-title red">
          <span class="icon"></span>
          <span class="red name">延期通知</span>
        </div>
        <div class="news-list">
          <div v-for="item in listCity" :key="item.name" class="lista">
            <a class="red" v-if="item.inline" @click="goInline(item)">
              {{ item.name }}
            </a>
            <template v-else-if="!item.src">
              <a class="red" @click="goInline(item)">
                {{ item.name }}
              </a>
            </template>
            <a class="red" v-else :href="item.src">{{ item.name }}</a>
          </div>
          <div v-for="item in listA" :key="item.name" class="lista">
            <a class="red" v-if="item.inline" @click="goInline(item)">
              {{ item.name }}
            </a>
            <template v-else-if="!item.src">
              <a class="red" @click="goInline(item)">
                {{ item.name }}
              </a>
            </template>
            <a class="red" v-else :href="item.src">{{ item.name }}</a>
          </div>
          <template v-if="isShowA">
            <div v-for="item in listARest" :key="item.name" class="lista">
              <a class="red" v-if="item.inline" @click="goInline(item)">
                {{ item.name }}
              </a>
              <template v-else-if="!item.src">
                <a class="red" @click="goInline(item)">
                  {{ item.name }}
                </a>
              </template>
              <a class="red" v-else :href="item.src">{{ item.name }}</a>
            </div>
          </template>
          <div class="more" @click="showMore(1)" v-if="listARest.length">
            <div class="red">{{ isShowA ? '收起' : '查看更多' }}</div>
            <img
              class="icon-img"
              src="@/assets/images/icon-red-b.png"
              alt="icon"
            />
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="province-news__block">
        <div class="news-title red">
          <span class="icon green"></span>
          <span class="green name">开考通知</span>
        </div>
        <div class="news-list">
          <div class="listb" v-for="(item, index) in listW" :key="index">
            <a class="yellow" :href="item.src">{{ item.name }}</a>
          </div>
          <div class="listb" v-for="(item, index) in listB" :key="index">
            <a class="green" v-if="item.inline" @click="goInline(item)">
              {{ item.name }}
            </a>
            <template v-else-if="!item.src">
              <a class="green" @click="goInline(item)">
                {{ item.name }}
              </a>
            </template>
            <a class="green" v-else :href="item.src">{{ item.name }}</a>
          </div>
          <template v-if="isShowB">
            <div class="listb" v-for="item in listBRest" :key="item.name">
              <a class="green" v-if="item.inline" @click="goInline(item)">
                {{ item.name }}
              </a>
              <template v-else-if="!item.src">
                <a class="green" @click="goInline(item)">
                  {{ item.name }}
                </a>
              </template>
              <a class="green" v-else :href="item.src">{{ item.name }}</a>
            </div>
          </template>
          <div class="more" @click="showMore(2)" v-if="listBRest.length">
            <div class="green">
              {{ isShowB ? '收起' : '查看更多' }}
            </div>
            <img
              class="icon-img"
              src="@/assets/images/icon-green-b.png"
              alt="icon"
            />
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="qr">
      <span class="qr-yd">
        <img src="@/assets/images/qrcode-yd.jpg" alt="qrcode" />
        <span>
          关注“自考树”公众号
          <br />
          及时获取自考信息
        </span>
      </span>
    </div>
    <div class="copy">
      <div class="copy-title">考前押题报名链接</div>
      <div class="copy-subtitle">复制下方淘口令到淘宝打开即可报名</div>
      <div class="copy-con">
        68￥fl6j2eDdgdU￥ https://m.tb.cn/h.fNbznKX 朗朗自考树押题
      </div>
    </div> -->
  </div>
</template>

<script>
import request from '@/utils/request.js'
import BannerPrivinceInfo from '@/components/banner/BannerPrivinceInfo.vue'
import PrivinceAll from '@/components/PrivinceAll.vue'
// import EpidemicInfo from '@/components/EpidemicInfo.vue'
export default {
  name: 'PrivinceInfo',
  components: {
    BannerPrivinceInfo,
    PrivinceAll,
    // EpidemicInfo,
  },
  props: {},

  data() {
    return {
      date: '',
      isShowA: false,
      isShowB: false,
      delayNum: 10,
      listCity: [
        // {
        //   name: '福建宁德市：2022年4月自学考试延期公告',
        //   inline: true,
        //   src: 'news?city=ningde',
        //   gmtUpdate: '2022-04-11 20:59:12',
        // },
        // {
        //   name: '福建泉州市：2022年4月自学考试延期公告',
        //   inline: true,
        //   src: 'news?city=quanzhou',
        //   gmtUpdate: '2022-04-09 12:59:12',
        // },
      ],
      listA: [
        {
          name: '天津：2022年4月自考延期公告',
          src: 'http://www.zhaokao.net/zkzx/system/2022/04/06/030004895.shtml',
        },
        {
          name: '山东：2022年4月自考延期公告',
          src: 'http://www.sdzk.cn/NewsInfo.aspx?NewsID=5703',
        },
        {
          name: '江苏：2022年4月自学考试停考公告',
          src:
            'https://www.jseea.cn/webfile/index/index_zkxx/2022-04-02/27625.html',
        },
      ],
      listARest: [
        {
          name: '河北：2022年4月自学考试延期公告',
          src:
            'http://www.hebeea.edu.cn/mobile/zxks/kwgl/2022/0402-151133-355.html',
        },
        {
          name: '黑龙江：2022年4月自学考试延期公告',
          src: 'https://www.lzk.hl.cn/zk/202204/t20220402_18095.htm',
        },
        {
          name: '浙江：2022年4月自学考试延期公告',
          src:
            'https://www.zjzs.net/moban/index/8a11f1547f76e1c5017fe9587c1804bd.html',
        },
        {
          name: '安徽：2022年4月自学考试延期公告',
          src: 'https://www.ahzsks.cn/gdjyzxks/5880.htm',
        },
        {
          name: '河南：2022年4月自学考试延期公告',
          src: 'http://www.haeea.cn/a/202204/42787.shtml',
        },
        {
          name: '上海：2022年4月自学考试延期公告',
          src: 'https://www.shmeea.edu.cn/page/04100/20220328/16236.html',
        },
        {
          name: '江西：2022年4月自学考试延期公告',
          src: 'http://www.jxeea.cn/art/2022/3/29/art_26656_3901068.html',
        },
        {
          name: '吉林：2022年4月自学考试延期公告',
          src: 'http://www.jleea.com.cn/2022/0330/4066.html',
        },
        {
          name: '泉州市：2022年4月自学考试延期公告！',
          inline: true,
          src: 'news?city=quanzhou',
          gmtUpdate: '2022-04-09 12:59:12',
        },
      ],
      listW: [
        // {
        //   name: '浙江：2022年4月自学考试疫情防控考生须知',
        //   src:
        //     'https://www.zjzs.net/moban/index/8a11f2c87f76eace017fb063267c019a.html',
        // },
      ],
      listB: [
        {
          name: '宁夏：2022年4月自学考试疫情防控公告',
          src:
            'https://www.nxjyks.cn/contents/ZXKS/2022/04/20220402145424000.html',
        },
        {
          name: '广西：2022年4月自学考试防疫须知',
          src: 'https://www.gxeea.cn/view/content_1013_27973.htm',
        },
        {
          name: '广东：2022年4月自学考试疫情防控考生须知',
          src: 'https://eea.gd.gov.cn/zxks/content/post_3901379.html',
        },
      ],
      listBRest: [
        {
          name: '北京：2022年4月自考考生健康监测要求的通知',
          src: 'https://www.bjeea.cn/html/selfstudy/xxfbt/2022/0331/81271.html',
        },
        {
          name: '福建：2022年4月自学考试疫情防控考生须知',
          src: 'https://www.eeafj.cn/zkgsgg/20220401/11887.html',
        },
        {
          name: '甘肃：2022年4月自学考试温馨提示',
          src: 'https://www.ganseea.cn/html/zxks/tzgg/7649.html',
        },
        {
          name: '湖北：2022年4月自学考试疫情防控考生须知',
          src: 'http://www.hbea.edu.cn/html/2022-04/12871.shtml',
        },
        {
          name: '云南：2022年4月自学考试防疫告知书',
          src: 'https://www.ynzs.cn/html/content/4801.html',
        },
        {
          name: '四川：2022年4月自学考试公告（二）',
          src: 'https://www.sceea.cn/Html/202203/Newsdetail_2545.html',
        },
        {
          name: '陕西：2022年4月自学考试疫情防控公告',
          src: 'http://www.sneea.cn/info/1024/9351.htm',
        },
        {
          name: '山西：2022年4月自学考试告知书',
          src: 'http://www.sxkszx.cn/news/2022328/n4659115415.html',
        },
        // {
        //   name: '浙江：2022年4月自学考试疫情防控考生须知',
        //   src:
        //     'https://www.zjzs.net/moban/index/8a11f2c87f76eace017fb063267c019a.html',
        // },
        {
          name: '贵州：2022年4月自学考试通知单等相关事宜的通知',
          src:
            'http://zsksy.guizhou.gov.cn/zxks/ksjh/202203/t20220324_73113590.html',
        },
        {
          name: '青海：2022年4月自学考试考生申报个人防疫信息的通告',
          src: 'http://www.qhjyks.com/ztzl/zxksz/3536.htm',
        },
        {
          name: '内蒙古：2022年4月自学考试现场信息采集及准考证打印的公告',
          src: 'https://www.nm.zsks.cn/tzgg/202203/t20220322_42345.html',
        },
        // {
        //   name: '湖南：2022年4月自学考试考生须知、考场规则、考生诚信考试承诺书',
        //   src:
        //     'https://zikao.hneao.cn/net/pages/net_Signup/self_zc_detail.jsp?zc_xh=1081',
        // },
        {
          name: '湖南：2022年4月自学考试疫情防控考生须知',
          src:
            'http://jyt.hunan.gov.cn/jyt/sjyt/hnsjyksy/ksyxwdt/202204/t20220401_22728138.html',
        },
        {
          name: '重庆市：2022年4月高等教育自学考试健康应试防疫须知！',
          inline: true,
          src: 'news?city=chongqin',
          gmtUpdate: '2022-04-05 16:59:12',
        },
      ],
    }
  },
  created() {
    let now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth() + 1
    let day = now.getDate()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    this.date = year + '-' + month + '-' + day
    this.init()
  },
  methods: {
    init() {
      this.getStartInfo()
      this.getStartInfo(1)
    },
    showMore(type) {
      if (type === 1) {
        this.isShowA = !this.isShowA
      } else {
        this.isShowB = !this.isShowB
      }
    },
    goInline(item) {
      if (item.src) {
        this.$router.push('/' + item.src)
      } else {
        this.$store.commit('setHtmlCon', item.area)
        this.$router.push('/' + 'news')
      }
    },
    getStartInfo(type = 0) {
      request
        .getStartInfo({ type })
        .then((res) => {
          console.log('res-getStartInfo')
          console.log(res)
          if (type === 0) {
            // this.data = res.result.concat(this.data2)
            // this.data = res.data.result
            let listA = res.data.result
            // listA.push({
            //   name: '福建泉州市：2022年4月自学考试延期公告！',
            //   inline: true,
            //   src: 'news?city=quanzhou',
            //   gmtUpdate: '2022-04-09 12:59:12',
            // })
            // listA.push(
            //   {
            //     name: '福建宁德市：2022年4月自学考试延期公告',
            //     inline: true,
            //     src: 'news?city=ningde',
            //     gmtUpdate: '2022-04-11 20:59:12',
            //   },
            //   {
            //     name: '福建泉州市：2022年4月自学考试延期公告',
            //     inline: true,
            //     src: 'news?city=quanzhou',
            //     gmtUpdate: '2022-04-09 12:59:12',
            //   },
            // )
            listA.sort((a, b) => {
              let numbA = a.gmtUpdate.replace(/\s|-|:/g, '')
              let numbB = b.gmtUpdate.replace(/\s|-|:/g, '')
              if (numbA < numbB) {
                return 1
              }
              if (numbA > numbB) {
                return -1
              }
              return 0
            })
            let num = 0
            listA = listA.map((item) => {
              if (item.provinceCode !== 'city') {
                num++
              }
              item.src = item.transLink || item.src || ''
              item.name = item.repair || item.name || ''
              return item
            })
            this.delayNum = num
            console.log(listA)
            console.log(listA.slice(0, 3))
            console.log(listA.slice(3))
            this.listA = listA.slice(0, 3)
            this.listARest = listA.slice(3)
          } else {
            let listA = res.data.result
            listA.sort((a, b) => {
              let numbA = a.gmtUpdate.replace(/\s|-|:/g, '')
              let numbB = b.gmtUpdate.replace(/\s|-|:/g, '')
              if (numbA < numbB) {
                return 1
              }
              if (numbA > numbB) {
                return -1
              }
              return 0
            })
            listA = listA.map((item) => {
              item.src = item.transLink || item.src || ''
              item.name = item.repair || item.name || ''
              return item
            })
            console.log(listA)
            console.log(listA.slice(0, 3))
            console.log(listA.slice(3))
            this.listB = listA.slice(0, 3)
            let listBRest = listA.slice(3)
            listBRest.push({
              name: '重庆市：2022年4月高等教育自学考试健康应试防疫须知！',
              inline: true,
              src: 'news?city=chongqin',
              gmtUpdate: '2022-04-05 16:59:12',
            })
            this.listBRest = listBRest
            // this.data2 = res.result
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
:root {
  --mainColor: rgb(2, 168, 2);
  --warningColor: rgb(146, 9, 9);
  --yellowColor: rgb(250, 167, 0);
}
.province-news {
  padding: 15px;
  .red {
    color: var(--warningColor);
  }
  .green {
    color: var(--mainColor);
  }
  .yellow {
    color: var(--yellowColor);
  }
  .icon {
    display: block;
    height: 1.5em;
    width: 10px;
    border-radius: 2px;
    background-color: var(--warningColor);
    margin-right: 10px;
  }
  .icon.green {
    background-color: var(--mainColor);
  }
  .icon-img {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .name {
    display: block;
    padding-top: 2px;
    font-size: 16px;
    font-weight: bold;
  }
  .lista {
    color: var(--warningColor);
    a {
      display: inline-block;
      padding: 5px 0;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .listb {
    color: var(--mainColor);
    a {
      display: inline-block;
      padding: 5px 0;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .title {
    background-color: var(--mainColor);
    color: #fff;
    text-align: center;
    font-size: 18px;
    padding: 12px 0;
    font-weight: bold;
  }
  .title-sub {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding: 10px 0;
  }
  .province-news__block {
    padding-bottom: 10px;
  }
  .line {
    margin: 0 15px;
    border-bottom: 1px solid #999;
  }
  .number {
    display: flex;
    justify-content: space-around;
    .left {
      text-align: center;
    }
    .right {
      text-align: center;
    }
    .number-title {
      font-size: 28px;
      font-weight: bold;
      padding: 20px 0 8px;
    }

    .number-subtitle {
      font-weight: bold;
    }
  }
  .news-title {
    padding: 10px 0;
    // height: 1.5em;
    // line-height: 1.5em;
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .more {
    padding-top: 8px;
    font-size: 14px;
    text-align: center;
  }
}
.qr {
  text-align: center;
  .qr-yd {
    // position: absolute;
    // right: 50px;
    // width: 160px;
    // display: none;
    padding: 10px 10px 20px;
    border-radius: 6px;
    background-color: #fff;
    // transform: translateY(-50%);
    // box-shadow: 0 0 10px $primary;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      width: 180px;
    }

    span {
      display: block;
      text-align: center;
      font-size: 20px;
      line-height: 1.8em;
      color: #999;
    }
  }
}
.copy {
  padding: 15px 15px 30px;
  &-title {
    font-weight: bold;
    padding-bottom: 10px;
  }
  &-subtitle {
    font-size: 14px;
  }
  &-con {
    padding-top: 10px;
    padding-left: 20px;
    font-size: 13px;
  }
}
</style>
